import { TokenInfoResponse } from '@adam-vault/adam-sdk';
import { BigNumber, ethers } from 'ethers';

type UnitName = 'wei' | 'kwei' | 'mwei' | 'gwei' | 'szabo' | 'finney' | 'ether';

type Unit = number | UnitName;

export const toUnitBN = (value: string | number, unit: Unit = 'ether'): BigNumber | null => {
  if (!value) {
    return null;
  }

  try {
    return ethers.utils.parseUnits(typeof value === 'number' ? value.toString() : value, unit);
  } catch {
    // eslint-disable-next-line no-console
    console.error('The amount is too low, it cannot be parsed');
    return null;
  }
};

export const toUnitString = (value: string | number, unit?: Unit): string => toUnitBN(value, unit)?.toString() || '';

export const fromUnit = (value: BigNumber | string, unit?: Unit): string => {
  const unitBN = typeof value === 'string' ? BigNumber.from(value) : value;

  // Optimize the return value: formatUnits returns values with .0 at the end
  const valueFromEthers = ethers.utils.formatUnits(unitBN, unit);
  const [realNumber, decimalNumber] = valueFromEthers.split('.');

  return decimalNumber && +decimalNumber > 0 ? valueFromEthers : realNumber;
};

export const fromTokenIdBN = (value: BigNumber): string => fromUnit(value, 0);

export const toWeiBN = (value: string | number): BigNumber | null => toUnitBN(value);

export const toWeiString = (value: string | number): string => toWeiBN(value)?.toString() || '';

export const fromWei = (value: BigNumber | string): string => fromUnit(value);

export const toSupportedTokenUnitBN = (
  supportedTokens: TokenInfoResponse[] | undefined,
  value: string,
  options: { address?: string; symbol?: string }
): BigNumber | null => {
  if (!supportedTokens) {
    return null;
  }

  let decimals = supportedTokens.find(
    (x) => x.address.toLowerCase() === options.address?.toLowerCase() || x.symbol === options.symbol
  )?.decimals;
  if (!decimals || Number.isNaN(decimals)) {
    decimals = 0;
  }
  return toUnitBN(value, decimals);
};

export const fromSupportedTokenUnit = (
  supportedTokens: TokenInfoResponse[] | undefined,
  value: BigNumber | string,
  options: { address?: string; symbol?: string }
): string => {
  if (!supportedTokens) {
    return '';
  }

  let decimals = supportedTokens.find(
    (x) => x.address.toLowerCase() === options.address?.toLowerCase() || x.symbol === options.symbol
  )?.decimals;
  if (!decimals || Number.isNaN(decimals)) {
    // hotfix for member token
    decimals = 18;
  }
  return fromUnit(value, decimals);
};

export const checkIsEthereumAddress = (value: string | undefined): boolean => !value || ethers.utils.isAddress(value);

export const getEthereumName = async (value: string, provider: ethers.providers.Provider): Promise<string> => {
  try {
    return (await provider.lookupAddress(value)) || '';
  } catch {
    return Promise.resolve('');
  }
};

/**
 * Get the ethereum address that owns the ethereum domain name
 * @param value ethereum domain name
 * @param provider
 * @returns ethereum address if the domain name is owned by an address, otherwise ''
 */
export const resolveEthereumAddress = async (value: string, provider: ethers.providers.Provider): Promise<string> =>
  (await provider.resolveName(value)) || '';

export const isValidAddressInput = async (
  value: string | undefined,
  provider: ethers.providers.Provider | null
): Promise<boolean> => {
  if (checkIsEthereumAddress(value)) {
    return true;
  }

  if (!!provider) {
    try {
      const ethereumName = await resolveEthereumAddress(value || '', provider);
      if (ethereumName) {
        return true;
      }
    } catch {
      return false;
    }
  }

  return false;
};

export const getEthereumAddress = async (
  value: string | null | undefined,
  provider: ethers.providers.Provider | null
): Promise<string> => {
  if (!value || provider === null) {
    return '';
  }
  if (!checkIsEthereumAddress(value)) {
    return resolveEthereumAddress(value, provider);
  }
  return value;
};

export const getEthereumAddresses = async (
  value: string | null | undefined,
  provider: ethers.providers.Provider | null
): Promise<string> => {
  if (!value || provider === null) {
    return '';
  }

  const inputs = value.split(',');
  const result: string[] = [];
  for await (let input of inputs) {
    input = input.trim();
    if (checkIsEthereumAddress(input)) {
      result.push(input);
    } else {
      const address = await resolveEthereumAddress(input, provider);
      result.push(address);
    }
  }

  return result.join(',');
};

const web3Utils = {
  toUnitBN,
  toUnitString,
  fromUnit,
  fromTokenIdBN,
  toWeiBN,
  toWeiString,
  fromWei,
  toSupportedTokenUnitBN,
  fromSupportedTokenUnit,
  checkIsEthereumAddress,
};

export default web3Utils;
