import { Env, Network as SDKNetwork } from '@adam-vault/adam-sdk';

export interface ChainInfoMapType {
  readonly [chainId: number]: ChainInfoType;
}

export interface ChainInfoType {
  readonly chainId: ChainId;
  // TODO update the prop name since it's quite confusing
  readonly chain: ChainL1 | ChainL2;
  readonly network: ChainNetwork;
  readonly isTestnet: boolean;
  readonly nativeCurrency: {
    name: string;
    symbol: string; // 2-6 characters long
    decimals: 18;
  };
  readonly name: string;
  readonly blockExplorerUrl: string;
  readonly publicRpcUrl?: string;
  readonly appUrl?: string;
  readonly envParam: {
    adamContractAddress: string;
    apiHostUrl: string;
    subgraphBaseUrl: string;
    privateRpcUrl: string;
  };
}

export enum ChainId {
  ETH_MAINNET = 1,
  ETH_GOERLI = 5,
  ETH_ARBITRUM_ONE = 42161,
  ETH_ARBITRUM_ONE_GOERLI = 421613,
  ETH_POLYGON = 137,
  ETH_POLYGON_MUMBAI = 80001,
}

export enum ChainL1 {
  ETH = 'Ethereum',
}

export enum ChainL2 {
  ARBITRUM_ONE = 'Arbitrum',
  POLYGON = 'Polygon',
}

/*
 * The following values are align with the Torus native supported network values at the moment.
 * If any of the following value doesn't match the Torus one, should create a mapping in wallet constants,
 * map and apply the corresponding value when init Torus.
 */
export enum ChainNetwork {
  MAINNET = 'mainnet',
  GOERLI = 'goerli',
  ARBITRUM_ONE = 'arbitrum-one',
  ARBITRUM_GOERLI = 'arbitrum-goerli',
  POLYGON = 'polygon',
  POLYGON_MUMBAI = 'mumbai',
}

export const ChainNetworkAdamSDKNetworkMapping: { [key in ChainNetwork]: SDKNetwork | null } = {
  [ChainNetwork.MAINNET]: SDKNetwork.MAINNET,
  [ChainNetwork.GOERLI]: SDKNetwork.GOERLI,
  [ChainNetwork.ARBITRUM_ONE]: SDKNetwork.ARBITRUM_ONE,
  [ChainNetwork.ARBITRUM_GOERLI]: SDKNetwork.ARBITRUM_GOERLI,
  [ChainNetwork.POLYGON]: null,
  [ChainNetwork.POLYGON_MUMBAI]: SDKNetwork.MUMBAI,
};

export const PRODUCTION_URL = 'https://app.adamvault.com/';
export const STAGING_URL = 'https://stg.adamvault.com/';

const ENV_PARAM: { [key in ChainL1 | ChainL2]: ChainInfoType['envParam'] } = {
  [ChainL1.ETH]: {
    adamContractAddress: process.env.REACT_APP_ADAM_CONTRACT_ADDRESS || '',
    apiHostUrl: process.env.REACT_APP_API_HOST || '',
    subgraphBaseUrl: process.env.REACT_APP_SUBGRAPH_BASE_URL || '',
    privateRpcUrl: process.env.REACT_APP_RPC_URL || '',
  },
  [ChainL2.ARBITRUM_ONE]: {
    adamContractAddress: process.env.REACT_APP_ARBITRUM_ONE_ADAM_CONTRACT_ADDRESS || '',
    apiHostUrl: process.env.REACT_APP_ARBITRUM_ONE_API_HOST || '',
    subgraphBaseUrl: process.env.REACT_APP_ARBITRUM_ONE_SUBGRAPH_BASE_URL || '',
    privateRpcUrl: process.env.REACT_APP_ARBITRUM_ONE_RPC_URL || '',
  },
  [ChainL2.POLYGON]: {
    adamContractAddress: process.env.REACT_APP_POLYGON_ADAM_CONTRACT_ADDRESS || '',
    apiHostUrl: process.env.REACT_APP_POLYGON_API_HOST || '',
    subgraphBaseUrl: process.env.REACT_APP_POLYGON_SUBGRAPH_BASE_URL || '',
    privateRpcUrl: process.env.REACT_APP_POLYGON_RPC_URL || '',
  },
};

const generateUrlWithNetworkParam = (baseUrl: string, network: ChainNetwork): string =>
  `${baseUrl}#/?network=${network}`;

export const CHAIN_INFO: ChainInfoMapType = {
  [ChainId.ETH_MAINNET]: {
    chainId: ChainId.ETH_MAINNET,
    chain: ChainL1.ETH,
    network: ChainNetwork.MAINNET,
    isTestnet: false,
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    name: 'Ethereum Mainnet',
    blockExplorerUrl: 'https://etherscan.io/',
    appUrl: generateUrlWithNetworkParam(PRODUCTION_URL, ChainNetwork.MAINNET),
    envParam: ENV_PARAM[ChainL1.ETH],
  },
  [ChainId.ETH_GOERLI]: {
    chainId: ChainId.ETH_GOERLI,
    chain: ChainL1.ETH,
    network: ChainNetwork.GOERLI,
    isTestnet: true,
    nativeCurrency: {
      name: 'Goerli Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    name: 'Goerli Testnet',
    blockExplorerUrl: 'https://goerli.etherscan.io/',
    appUrl: generateUrlWithNetworkParam(
      process.env.REACT_APP_ENV === Env.PROD ? STAGING_URL : process.env.REACT_APP_BASE_PATH || '',
      ChainNetwork.GOERLI
    ),
    envParam: ENV_PARAM[ChainL1.ETH],
  },

  // Arbitrum chains information from: https://developer.arbitrum.io/public-chains
  [ChainId.ETH_ARBITRUM_ONE]: {
    chainId: ChainId.ETH_ARBITRUM_ONE,
    chain: ChainL2.ARBITRUM_ONE,
    network: ChainNetwork.ARBITRUM_ONE,
    isTestnet: false,
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    name: 'Arbitrum',
    blockExplorerUrl: 'https://arbiscan.io/',
    publicRpcUrl: 'https://arb1.arbitrum.io/rpc',
    appUrl: generateUrlWithNetworkParam(PRODUCTION_URL, ChainNetwork.ARBITRUM_ONE),
    envParam: ENV_PARAM[ChainL2.ARBITRUM_ONE],
  },
  [ChainId.ETH_ARBITRUM_ONE_GOERLI]: {
    chainId: ChainId.ETH_ARBITRUM_ONE_GOERLI,
    chain: ChainL2.ARBITRUM_ONE,
    network: ChainNetwork.ARBITRUM_GOERLI,
    isTestnet: true,
    nativeCurrency: {
      name: 'Goerli Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    name: 'Arbitrum Goerli Testnet',
    blockExplorerUrl: 'https://goerli.arbiscan.io',
    publicRpcUrl: 'https://goerli-rollup.arbitrum.io/rpc',
    appUrl: generateUrlWithNetworkParam(
      process.env.REACT_APP_ENV === Env.PROD ? STAGING_URL : process.env.REACT_APP_BASE_PATH || '',
      ChainNetwork.ARBITRUM_GOERLI
    ),
    envParam: ENV_PARAM[ChainL2.ARBITRUM_ONE],
  },

  // Polygon chains information from: https://wiki.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/
  [ChainId.ETH_POLYGON]: {
    chainId: ChainId.ETH_POLYGON,
    chain: ChainL2.POLYGON,
    network: ChainNetwork.POLYGON,
    isTestnet: false,
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    name: 'Polygon Testnet',
    blockExplorerUrl: 'https://polygonscan.com',
    publicRpcUrl: 'https://polygon-rpc.com',
    appUrl: generateUrlWithNetworkParam(
      process.env.REACT_APP_ENV === Env.PROD ? STAGING_URL : process.env.REACT_APP_BASE_PATH || '',
      ChainNetwork.POLYGON
    ),
    envParam: ENV_PARAM[ChainL2.POLYGON],
  },
  [ChainId.ETH_POLYGON_MUMBAI]: {
    chainId: ChainId.ETH_POLYGON_MUMBAI,
    chain: ChainL2.POLYGON,
    network: ChainNetwork.POLYGON_MUMBAI,
    isTestnet: true,
    nativeCurrency: {
      name: 'Matic Mumbai',
      symbol: 'MATIC',
      decimals: 18,
    },
    name: 'Polygon Mumbai Testnet',
    blockExplorerUrl: 'https://mumbai.polygonscan.com',
    /*
     * Since the public RPC provided by Polygon official id not working, we change to use the following RPC. Ref: https://chainlist.org/chain/80001
     * p.s. At the time we test, only omniatech RPC can successfully interact with contracts on chain amoung the RPCs in the list.
     */
    publicRpcUrl: 'https://endpoints.omniatech.io/v1/matic/mumbai/public',
    appUrl: generateUrlWithNetworkParam(
      process.env.REACT_APP_ENV === Env.PROD ? STAGING_URL : process.env.REACT_APP_BASE_PATH || '',
      ChainNetwork.POLYGON_MUMBAI
    ),
    envParam: ENV_PARAM[ChainL2.POLYGON],
  },
};

export const CHAINS: ChainInfoType[] = Object.values(CHAIN_INFO);

const DEFAULT_SUPPORTED_CHAINS = [CHAIN_INFO[ChainId.ETH_MAINNET], CHAIN_INFO[ChainId.ETH_GOERLI]];
export const SUPPORTED_CHAINS =
  process.env.REACT_APP_SUPPORTED_NETWORKS?.split(',').reduce((chainInfos, networkString: string) => {
    const chainInfo = CHAINS.find((info) => info.network === (networkString as ChainNetwork));
    return !!chainInfo ? chainInfos.concat([chainInfo]) : chainInfos;
  }, [] as ChainInfoType[]) || DEFAULT_SUPPORTED_CHAINS;

const getPlatformSelectedChainIdFromSession = (): ChainId | undefined => {
  const chainIdFromSession = parseInt(sessionStorage.platformSelectedChainId, 10);
  return Number.isNaN(chainIdFromSession) ? undefined : chainIdFromSession;
};

// Priority: Session storage network -> REACT_APP_DEFAULT_NETWORK -> GOERLI
export const DEFAULT_NETWORK =
  CHAIN_INFO[getPlatformSelectedChainIdFromSession() as ChainId]?.network ||
  process.env.REACT_APP_DEFAULT_NETWORK ||
  ChainNetwork.GOERLI;

// Since DEFAULT_NETWORK always has valid value, networkUtils.getChainInfo(DEFAULT_NETWORK) won't be null
export const DEFAULT_CHAIN_ID = (CHAINS.find((info) => info.network === DEFAULT_NETWORK) as ChainInfoType).chainId;

export default CHAIN_INFO;
