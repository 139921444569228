import { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

export interface PortalProps {
  parentElementId?: string;
  children: React.ReactElement;
}

// TODO Put into utils/
/**
 * Generates a random string of 24 characters
 * @return {string} the generated random string.
 */
const generateRandomString = (): string => {
  const BASE_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const STRING_LENGTH = 24;

  let result = 'portal__';
  for (let i = 0; i < STRING_LENGTH; i++) {
    result += BASE_CHARS.charAt(Math.floor(Math.random() * BASE_CHARS.length));
  }

  return result;
};

function createParent(id: string): HTMLDivElement {
  const parent = document.createElement('div');
  parent.setAttribute('id', id);
  return parent;
}

function addParentElement(parentElem: Element): void {
  if (document.body) {
    document.body.appendChild(parentElem);
  }
}

export const Portal: React.FC<PortalProps> = ({ parentElementId: idFromProps, children }) => {
  const [parent, setParent] = useState<Element | null>(null);
  const [isParentCreated, setIsParentCreated] = useState(false);
  /*
   * Assign an id value to the portal parent element if not defined in props. Only assign once to make sure
   * all portal parent elements are removed after unmount.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const parentElementId = useMemo(() => idFromProps || generateRandomString(), []);

  useEffect(() => {
    const assignParent = (): void => {
      const existingParent = document.querySelector(`#${parentElementId}`);
      const parentElem = existingParent || createParent(parentElementId);

      if (!existingParent) {
        addParentElement(parentElem);
        setIsParentCreated(true);
      }

      setParent(parentElem);
    };

    if (document.readyState !== 'complete') {
      // Make sure the document elements are loaded
      window.addEventListener('load', assignParent);
    } else {
      assignParent();
    }

    return () => {
      window.removeEventListener('load', assignParent);
    };
  }, [parentElementId]);

  // Teardown of the parent element
  useEffect(
    () => () => {
      if (isParentCreated) {
        parent?.remove();
      } else {
        while (parent?.childElementCount) {
          parent.firstChild?.remove();
        }
      }
    },
    [isParentCreated, parent]
  );

  return parent && createPortal(children, parent);
};
