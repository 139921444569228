import React, { InputHTMLAttributes } from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
import HookFormFieldProps from '../../../types/HookFormFieldProps';

export interface StylelessInputProps extends HookFormFieldProps, InputHTMLAttributes<HTMLInputElement> {}

interface UncontrolledInputProps extends InputHTMLAttributes<HTMLInputElement> {
  setRef?: (el: React.ReactNode, name: string) => void;
}

export const Input = styled.input`
  width: 100%;
  margin: 0;
  position: relative;
  font-weight: 400;
  outline: none;
  border: none;
  line-height: 1.5;
  padding: 0;
  background-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  font-size: 14px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
`;

function ControlledInput(props: StylelessInputProps): JSX.Element {
  const { name = 'input', control, setRef, ...baseProps } = props;

  const { field } = useController({ name, control });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (baseProps.onChange) {
      baseProps.onChange(e);
    }
    field.onChange(e);
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (baseProps.onBlur) {
      baseProps.onBlur(e);
    }
    field.onBlur();
  };

  return (
    <Input
      {...baseProps}
      name={name}
      value={baseProps.value ?? field.value}
      onChange={onChange}
      onBlur={onBlur}
      ref={(el) => setRef && setRef(el as React.ReactNode, name)}
    />
  );
}

function UncontrolledInput(props: UncontrolledInputProps): JSX.Element {
  const { name, setRef, ...baseProps } = props;

  return (
    <Input
      {...baseProps}
      name={name}
      value={baseProps.value}
      ref={(el) => setRef && setRef(el as React.ReactNode, name || '')}
    />
  );
}

export function StylelessInput(props: StylelessInputProps): JSX.Element {
  const { control, ...baseProps } = props;
  return control ? <ControlledInput {...props} /> : <UncontrolledInput {...baseProps} />;
}
