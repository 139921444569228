import { Env } from '@adam-vault/adam-sdk';
import Torus, { ETHEREUM_NETWORK_TYPE } from '@toruslabs/torus-embed';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { ethers } from 'ethers';
import CHAIN_INFO, { ChainId, ChainInfoType } from './chain';
import metaMaskLogo from '../assets/images/logo_metamask.svg';
import torusLogo from '../assets/images/logo_torus.svg';
import walletConnectLogo from '../assets/images/logo_wallet_connect.svg';

export enum WalletType {
  METAMASK = 'metamask',
  TORUS = 'torus',
  WALLET_CONNECT = 'wallet_connect',
}

type MetaMaskConnectorType = ethers.providers.ExternalProvider;
type TorusConnectorType = Torus;
type WalletConnectConnectorType = WalletConnectProvider;

export type Connector = MetaMaskConnectorType | TorusConnectorType | WalletConnectConnectorType;

type ConnectData = {
  isTryingToReconnect?: boolean;
  email?: string;
};

// From MetaMask Docs: https://docs.metamask.io/guide/rpc-api.html#unrestricted-methods
type AddEthereumChainParameter = {
  chainId: string; // A 0x-prefixed hexadecimal string
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string; // 2-6 characters long
    decimals: 18;
  };
  rpcUrls: string[];
  blockExplorerUrls?: string[];
  iconUrls?: string[]; // Currently ignored.
};

export type WalletItem = {
  type: WalletType;
  name: string;
  gaName?: string;
  logo: string;
  url: string;
  downloadUrl?: string;
  isInstalled: boolean;
  init: (chainId: ChainId) => Promise<Connector>;
  connect: (connector: Connector, connectData?: ConnectData) => Promise<ethers.providers.Web3Provider | null>;
  disconnect: (connector: Connector) => Promise<void>;
  // May merge these two functions into one, will be decided after implemented the switch/import network functions of other wallets
  switchNetwork?: (Connector: Connector, chainId: ChainId) => Promise<void>;
  importNetwork?: (Connector: Connector, chainInfo: ChainInfoType) => Promise<void>;
};

export type WalletMapping = {
  [key in WalletType]: WalletItem;
};

const metaMaskImportAndSwitchNetwork = async (
  metaMaskConnector: MetaMaskConnectorType,
  chainInfo: ChainInfoType
): Promise<void> => {
  if (!metaMaskConnector.request) {
    throw new Error('Failed to use request network change');
  }

  if (!chainInfo.publicRpcUrl) {
    // Default networks supported by MetaMask, so can direct switch
    return metaMaskConnector.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: `0x${chainInfo.chainId.toString(16)}` }], // chainId must be in hexadecimal numbers
    });
  }

  const networkInfo: AddEthereumChainParameter = {
    chainId: `0x${chainInfo.chainId.toString(16)}`,
    chainName: chainInfo.name,
    nativeCurrency: chainInfo.nativeCurrency,
    rpcUrls: [chainInfo.publicRpcUrl],
    blockExplorerUrls: [chainInfo.blockExplorerUrl],
  };

  return metaMaskConnector.request({
    method: 'wallet_addEthereumChain',
    params: [networkInfo],
  });
};

/*
 * Checker function to check if the network is natively supported by Torus. ETHEREUM_NETWORK_TYPE is a type from @toruslabs/torus-embed,
 * once it got updated, eslint will prompt error in this switch case and ask for updates.
 */
const isNetworkNativelySupportedByTorus = (network: ETHEREUM_NETWORK_TYPE | string): boolean => {
  switch (network) {
    case 'ropsten':
    case 'rinkeby':
    case 'kovan':
    case 'mainnet':
    case 'goerli':
    case 'localhost':
    case 'matic':
    case 'mumbai':
    case 'xdai':
    case 'bsc_mainnet':
    case 'bsc_testnet':
      return true;
  }

  return false;
};

export const Wallet: WalletMapping = {
  [WalletType.METAMASK]: {
    type: WalletType.METAMASK,
    name: 'MetaMask',
    gaName: 'MetaMask',
    logo: metaMaskLogo,
    url: 'https://metamask.io/',
    downloadUrl: 'https://metamask.io/download/',
    isInstalled: !!window.ethereum,
    init: async () => window.ethereum,
    connect: async (connector, { isTryingToReconnect } = {}) => {
      const providerSource = connector;
      const provider = new ethers.providers.Web3Provider(providerSource as MetaMaskConnectorType, 'any');
      if (!isTryingToReconnect) {
        await provider.send('eth_requestAccounts', []);
      }
      return provider;
    },
    disconnect: async (connector) => {
      const metaMaskConnector = connector as MetaMaskConnectorType;
      if (!metaMaskConnector.request) {
        throw new Error('Failed to use disconnect MetaMask wallet');
      }

      return metaMaskConnector.request({
        method: 'eth_requestAccounts',
        params: [{ eth_accounts: {} }],
      });
    },
    switchNetwork: async (connector, chainId) => {
      const metaMaskConnector = connector as MetaMaskConnectorType;
      const chainInfo = CHAIN_INFO[chainId];
      return metaMaskImportAndSwitchNetwork(metaMaskConnector, chainInfo);
    },
    importNetwork: async (connector, chainInfo: ChainInfoType) => {
      const metaMaskConnector = connector as MetaMaskConnectorType;
      return metaMaskImportAndSwitchNetwork(metaMaskConnector, chainInfo);
    },
  },

  [WalletType.TORUS]: {
    type: WalletType.TORUS,
    name: 'Torus',
    gaName: 'Torus',
    logo: torusLogo,
    url: 'https://tor.us/',
    downloadUrl: '',
    isInstalled: true,
    init: async (chainId: ChainId) => {
      const chain = CHAIN_INFO[chainId];
      const torus = new Torus({
        buttonPosition: 'bottom-right',
      });

      await torus.init({
        network: {
          host: isNetworkNativelySupportedByTorus(chain.network) ? chain.network : chain.publicRpcUrl || '',
          chainId,
          networkName: chain.name,
        },
        buildEnv: process.env.REACT_APP_ENV === Env.PROD ? 'production' : 'testing',
        enableLogging: process.env.REACT_APP_ENV === Env.DEV,
        whiteLabel: {
          theme: {
            isDark: true,
            colors: {
              // Torus blue
              torusBrand1: '#044ef6',
            },
          },
          logoDark: '',
          logoLight: '',
          topupHide: false,
          featuredBillboardHide: true,
          disclaimerHide: false,
        },
      });
      return torus;
    },
    connect: async (connector) => {
      const torusConnector = connector as TorusConnectorType;
      try {
        await torusConnector.login();
      } catch (error) {
        await torusConnector.cleanUp();
        throw error;
      }

      return new ethers.providers.Web3Provider(torusConnector.provider);
    },
    disconnect: async (connector) => {
      const torusConnector = connector as TorusConnectorType;
      await torusConnector.logout();
      await torusConnector.cleanUp();
    },
  },

  [WalletType.WALLET_CONNECT]: {
    type: WalletType.WALLET_CONNECT,
    name: 'WalletConnect',
    gaName: 'WalletConnect',
    logo: walletConnectLogo,
    url: 'https://walletconnect.com/',
    downloadUrl: '',
    isInstalled: true,
    init: async (chainId: ChainId) =>
      new WalletConnectProvider({
        chainId,
        rpc: {
          [chainId]: CHAIN_INFO[chainId].envParam.privateRpcUrl || '',
        },
      }),
    connect: async (connector: Connector) => {
      const wcConnector = connector as WalletConnectConnectorType;
      await wcConnector.enable();
      return new ethers.providers.Web3Provider(wcConnector);
    },
    disconnect: async (connector: Connector) => {
      const wcConnector = connector as WalletConnectConnectorType;
      await wcConnector.disconnect();
    },
  },
};

export const BEGINNER_WALLETS = [Wallet[WalletType.TORUS]];

export const ADVANCED_WALLETS = [Wallet[WalletType.METAMASK], Wallet[WalletType.WALLET_CONNECT]];

export const ALL_WALLETS = [Wallet[WalletType.METAMASK], Wallet[WalletType.TORUS], Wallet[WalletType.WALLET_CONNECT]];

export default Wallet;
