import ethers from 'ethers';

import { ChainId, ChainInfoType, ChainNetwork } from '../constants/chain';
import Wallet, { Connector, WalletType } from '../constants/wallet';
import networkUtils from '../utils/networkUtils';

export interface Web3 {
  isReady?: boolean;
  isConnecting?: boolean;
  isConnected?: boolean;
  connector?: Connector | null;
  provider?: ethers.providers.Provider | null;
  signer?: ethers.Signer | null;
  chainId?: number | null;
  address?: string | null;
  walletType?: WalletType | null;
  error?: Error | null;
  isEOAAddressUpdated?: boolean;
}

export type Web3Params = {
  platformSelectedChainId: ChainId;
  web3: Web3;
  setWeb3: (updatedWeb3: Partial<Web3>) => Promise<void>;
};

export type UseWeb3ReturnType = Web3 & {
  connectEOA: (walletType: WalletType, isTryingToReconnect?: boolean) => Promise<void>;
  tryToReconnectEOA: () => Promise<void>;
  disconnectEOA: () => Promise<void>;
  switchNetwork: (network: ChainNetwork) => Promise<void>;
  importNetwork: ((chainInfo: ChainInfoType) => Promise<void>) | null;
  setWeb3Ready: (isReady: boolean) => void;
};

const useWeb3 = (params: Web3Params): UseWeb3ReturnType => {
  const { platformSelectedChainId, web3, setWeb3 } = params;

  const connectEOA = async (walletType: WalletType, isTryingToReconnect = false): Promise<void> => {
    if (web3.isConnected) {
      return Promise.resolve();
    }

    setWeb3({ isConnecting: true, error: null });

    const wallet = Wallet[walletType];

    // Redirect to the wallet download page if user haven't downloaded the wallet
    if (!wallet.isInstalled) {
      setWeb3({ isConnecting: false });

      if (!isTryingToReconnect) {
        window.open(wallet.downloadUrl);
      }

      return;
    }

    // Connect the wallet
    try {
      const connector = await wallet.init(platformSelectedChainId);
      const provider = await wallet.connect(connector);

      const signer = provider?.getSigner() || null;

      const eoaAddress = await signer?.getAddress();
      setWeb3({
        isConnecting: false,
        connector,
        provider,
        signer,
        address: eoaAddress?.toLowerCase(),
        walletType,
      });
    } catch (error) {
      const e = error as unknown as Error;
      // eslint-disable-next-line no-console
      console.warn('Failed to connect the wallet', error);
      setWeb3({ isConnecting: false, error: e, connector: null, provider: null, signer: null, walletType: null });
    }
  };

  const tryToReconnectEOA = (): Promise<void> => {
    if (!web3.walletType) {
      return Promise.resolve();
    }

    return connectEOA(web3.walletType, true);
  };

  const disconnectEOA = async (): Promise<void> => {
    if (!web3.walletType || !web3.connector) {
      return;
    }

    const wallet = Wallet[web3.walletType];

    try {
      await wallet.disconnect(web3.connector);
      setWeb3({ error: null, connector: null, provider: null, signer: null, walletType: null, address: null });
    } catch (error) {
      const e = error as unknown as Error;
      // eslint-disable-next-line no-console
      console.warn('Failed to disconnect the wallet', error);
      setWeb3({ error: e });
    }
  };

  const switchNetwork = async (network: ChainNetwork): Promise<void> => {
    if (!web3.walletType || !web3.connector) {
      return;
    }

    const wallet = Wallet[web3.walletType];
    const chainId = networkUtils.getChainInfo(network)?.chainId;

    if (!wallet.switchNetwork || !chainId) {
      return;
    }

    try {
      await wallet.switchNetwork(web3.connector, chainId);
    } catch (error) {
      const e = error as unknown as Error;
      // eslint-disable-next-line no-console
      console.warn('Failed to switch network', error);
      setWeb3({ error: e });
    }
  };

  const importNetwork =
    web3.connector && web3.walletType && Wallet[web3.walletType as WalletType]?.importNetwork
      ? async (chainInfo: ChainInfoType): Promise<void> => {
          const wallet = Wallet[web3.walletType as WalletType];
          if (!web3.connector || !wallet.importNetwork) {
            return;
          }

          wallet.importNetwork(web3.connector, chainInfo);
        }
      : null;

  const setWeb3Ready = (isReady: boolean): void => {
    setWeb3({ isReady });
  };

  return {
    ...web3,
    connectEOA,
    tryToReconnectEOA,
    disconnectEOA,
    switchNetwork,
    importNetwork,
    setWeb3Ready,
  };
};

export default useWeb3;
